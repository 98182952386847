import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { GenericHttpService } from "./generic-http.service";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class VersionService extends GenericHttpService {
  public osirisVersion: string = "?";

  setOsirisVersion(): void {
    this.httpClient
      .get<{ version: string }>("assets/osiris_version.json")
      .pipe(
        catchError((error: HttpErrorResponse): Observable<{ version: string }> => {
          if (error.message.includes("localhost")) {
            return of({ version: "localhost" });
          }
          console.error("Error reading the file assets/osiris_version.json:", error);
          return of({ version: "unknown" });
        }),
      )
      .subscribe((data) => {
        this.osirisVersion = data.version;
      });
  }
}
